import React from "react";
import { navigate } from "gatsby";
import { PageProps } from "gatsby";
import { Layout, Container } from "components/layout";
import { useAuth } from "auth";
import { ViewHeader } from "components/view/ViewHeader";
import {
  Form,
  FormButton,
  FormColumn,
  FormContent,
  FormRow,
  FormTextInput,
  FormTitle,
  FormSuccessLabel,
} from "components/common/Form";
import { useKey } from "hooks/api";
import { AdminSidebar } from "components/view/AdminSidebar";
import { CopyToClipboardField } from "components/view/CopyToClipboardField";

interface AdminNewKeyPageProps extends PageProps { }

export default function AdminNewKeyPage(props: AdminNewKeyPageProps) {
  const { token } = useAuth();
  const key = useKey('');
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    key?.createKey.cleanErrors();
  }, []);

  React.useEffect(() => {
    if (!token) navigate("/login");
  }, [token]);

  const handleSubmit = async (data: FormData) => {
    try {
      await key?.createKey.process(data).then((success) => {
        if (success) setDisabled(true);
        return success;
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Layout>
      <ViewHeader />
      <Container hasSidebar>
        <AdminSidebar {...props} />
        <Container noPadding>
          <Form onSubmit={handleSubmit}>
            <FormTitle>New API Key</FormTitle>
            <FormContent>
              <FormRow>
                <FormColumn>
                  <FormTextInput
                    field="name"
                    error={key.createKey.errors?.["name"]?.[0]}
                    label="Name"
                    disabled={disabled}
                    formatError={(error) => {
                      switch (error) {
                        case "This field may not be blank.":
                          return "Enter a name for this key.";
                        default:
                          return error;
                      }
                    }}
                  />
                </FormColumn>
                <FormColumn flex>
                  <FormButton transparent disabled={disabled}>
                    Create Key
                  </FormButton>
                </FormColumn>
              </FormRow>
            </FormContent>
            {key.createKey.data?.apikey && (
              <>
                <FormSuccessLabel>
                  New API Key created successfully
                </FormSuccessLabel>
                <CopyToClipboardField>{key.createKey.data.apikey}</CopyToClipboardField>
              </>
            )}
          </Form>
        </Container>
      </Container>
    </Layout>
  );
}
