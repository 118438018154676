import React from "react";
import { copyTextToClipboard } from "store/utils";
import { className } from "utils/className";
import { ClipboardIcon } from "components/Icons";
import {
  copyToClipboardField,
  copiedTooltip,
  showCopiedTooltip,
} from "./CopyToClipboardField.module.scss";

export function CopyToClipboardField(props: React.PropsWithChildren<{}>) {
  const ref = React.useRef<HTMLDivElement>(null);

  const [copied, setCopied] = React.useState(false);

  const handleClick = React.useCallback(() => {
    copyTextToClipboard(ref.current?.innerText || "").then((success) => {
      if (success) {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 750);
      }
    });
  }, []);

  return (
    <button
      type="button"
      className={copyToClipboardField}
      onClick={handleClick}
    >
      <label>
        <div ref={ref}>{props.children}</div>

        <div
          className={className(copiedTooltip, copied ? showCopiedTooltip : "")}
        >
          Copied!
        </div>
      </label>
      <div>
        <ClipboardIcon />
      </div>
    </button>
  );
}
